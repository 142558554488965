import {
  OrderStatus,
  EcomOrderStatus,
  RestaurantsOrderStatus,
} from '@wix/restaurants-types';
import { UNKNOWN } from '../constants/orderStatusState';
import values from 'lodash/values';

const isRestaurantsStatus = (status: any): status is RestaurantsOrderStatus => {
  return values(RestaurantsOrderStatus).includes(status);
};

export const getOrderStatus = (
  restaurantStatus: string | undefined,
  ecomStatus: OrderStatus | undefined,
) => {
  if (ecomStatus === OrderStatus.CANCELED) {
    return EcomOrderStatus.CANCELED;
  }

  if (isRestaurantsStatus(restaurantStatus)) {
    return restaurantStatus;
  }

  return UNKNOWN;
};
