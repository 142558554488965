import { InitState, OrderInfo } from './utils/types';
import { runInAction } from 'mobx';

export function initOrderState(initState: InitState) {
  const stateDefaultValue: OrderInfo = {
    orderNumber: 'None',
    orderDetails: [],
  };

  const { state: orderInfo } = initState(stateDefaultValue);

  const updateOrderInfo = (updatedOrder: Partial<OrderInfo>) => {
    runInAction(() => {
      Object.keys(updatedOrder).forEach((key) => {
        orderInfo[key] = updatedOrder[key];
      });
    });
  };

  return { orderInfo, updateOrderInfo };
}
