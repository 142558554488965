import model from './model';
import { UNKNOWN } from '../../constants/orderStatusState';
import { selectors } from './selectors';
import { runInAction } from 'mobx';
import {
  statusTitleTranslationKeysMap,
  statusDescriptionTranslationKeysMap,
} from './statusTranslationKeysMap';
import { getOrderStatus } from '../../utils/utils';

export default model.createController(
  ({ $bind, initState, $widget, flowAPI }) => {
    const { state } = initState({ status: UNKNOWN });

    const updateStatus = (newStatus: string) => {
      runInAction(() => {
        state.status = newStatus;
      });
    };

    const setStatus = (status: string) => {
      if (state.status !== status) {
        updateStatus(status);
      }
    };

    const initDuplexer = async (orderId: string) => {
      await import('../../services/orderDuplexer').then(({ OrderDuplexer }) => {
        const orderDuplexer = new OrderDuplexer(orderId, flowAPI);

        orderDuplexer.onOrderUpdate(
          ({ restaurantsFulfillmentStatus, ecomStatus }) => {
            updateStatus(
              getOrderStatus(restaurantsFulfillmentStatus, ecomStatus),
            );
          },
        );
      });
    };

    return {
      pageReady: async () => {
        if (flowAPI.environment.isEditor || flowAPI.environment.isPreview) {
          return;
        }
        const t = flowAPI.translations.t;
        $bind(selectors.statusLabel, {
          text: () => t('tracker.status.label'),
        });
        $bind(selectors.statusTitle, {
          text: () => {
            const statusTranslationKey =
              statusTitleTranslationKeysMap[state.status];
            const translatedStatus = statusTranslationKey
              ? t(statusTranslationKey)
              : t(statusTitleTranslationKeysMap.UNKNOWN);

            return translatedStatus;
          },
        });
        $bind(selectors.statusDescription, {
          text: () => {
            const statusTranslationKey =
              statusDescriptionTranslationKeysMap[state.status];
            const translatedStatus = statusTranslationKey
              ? t(statusTranslationKey)
              : t(statusDescriptionTranslationKeysMap.UNKNOWN);

            return translatedStatus;
          },
        });
        $widget.fireEvent('widgetLoaded', {});
      },
      exports: {
        setStatus,
        initDuplexer,
      },
    };
  },
);
