import { getOrder } from '@wix/ambassador-ecom-v1-order/http';
import { factory, orderSanitizer } from '@wix/restaurants-api';
import { Order } from '@wix/restaurants-types';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export async function getOrderInfo(
  httpClient: IHttpClient,
  orderId: string,
): Promise<Order> {
  const {
    data: { order },
  } = await httpClient.request(
    getOrder(factory.requestOptions.getOrder(orderId)),
  );

  if (!order) {
    throw new Error(
      `[Order Tracker] - get Order: Error when calling getOrder, Get empty order for orderId ${orderId}.`,
    );
  }

  return orderSanitizer(order);
}
