import { FulfillmentMethodType } from '@wix/restaurants-normalizers';
import { TrackerFulfillmentType } from '@wix/restaurants-bi';

export const mapFulfillmentMethodBI: Record<
  FulfillmentMethodType,
  TrackerFulfillmentType
> = {
  [FulfillmentMethodType.PICKUP]: TrackerFulfillmentType.PICKUP,
  [FulfillmentMethodType.DELIVERY]: TrackerFulfillmentType.DELIVERY,
  [FulfillmentMethodType.NONE]: TrackerFulfillmentType.NONE,
};

export const mapMethodDetailsTitleKey: Record<FulfillmentMethodType, string> = {
  [FulfillmentMethodType.PICKUP]: 'tracker.page.pickup-details.title',
  [FulfillmentMethodType.DELIVERY]: 'tracker.page.delivery-details.title',
  [FulfillmentMethodType.NONE]:
    'tracker.page.unknown-fulfillment-details.title',
};
