import {
  EcomOrRestaurantsOrderStatus,
  RestaurantsOrderStatus,
  EcomOrderStatus,
} from '@wix/restaurants-types';
import { UNKNOWN } from '../../constants/orderStatusState';
import { TranslationKeys } from '../../types/types';

export const statusTitleTranslationKeysMap: {
  [key in EcomOrRestaurantsOrderStatus | typeof UNKNOWN]: TranslationKeys;
} = {
  [RestaurantsOrderStatus.PENDING_APPROVAL]:
    'tracker.status.order-pending.title',
  [RestaurantsOrderStatus.ACCEPTED]: 'tracker.status.order-accepted.title',
  [RestaurantsOrderStatus.READY]: 'tracker.status.order-ready.title',
  [RestaurantsOrderStatus.OUT_FOR_DELIVERY]:
    'tracker.status.order-out-for-delivery.title',
  [RestaurantsOrderStatus.FULFILLED]: 'tracker.status.order-fulfilled.title',
  [EcomOrderStatus.CANCELED]: 'tracker.status.order-canceled.title',
  [UNKNOWN]: 'tracker.status.order-unknown.title',
};

export const statusDescriptionTranslationKeysMap: {
  [key in EcomOrRestaurantsOrderStatus | typeof UNKNOWN]: TranslationKeys;
} = {
  [RestaurantsOrderStatus.PENDING_APPROVAL]:
    'tracker.status.order-pending.description',
  [RestaurantsOrderStatus.ACCEPTED]:
    'tracker.status.order-accepted.description',
  [RestaurantsOrderStatus.READY]: 'tracker.status.order-ready.description',
  [RestaurantsOrderStatus.OUT_FOR_DELIVERY]:
    'tracker.status.order-out-for-delivery.description',
  [RestaurantsOrderStatus.FULFILLED]:
    'tracker.status.order-fulfilled.description',
  [EcomOrderStatus.CANCELED]: 'tracker.status.order-canceled.description',
  [UNKNOWN]: 'tracker.status.order-unknown.description',
};
