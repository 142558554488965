export const selectors = {
  stateBox: '#multiStateBox1',
  orderNumber: '#OrderNumber',
  trackerWidget: '#tracker2',
  methodDetailsTitle: '#DeliveryDetailsTitle',
  methodDetailsRepeater: '#DeliveryDetails',
  orderDetailsItemTitle: '#text25',
  orderDetailsItemValue: '#text24',
  RestaurantDetailsBox: '#RestDetailsBox',
  title: '#Title',
  orderNumberLabel: '#orderNumberlabel',
  errorStateText: '#TrackerPageErrorText',
} as const;

export const states = {
  ready: 'TrackerPage',
  loader: 'TrackerLoader',
  error: 'TrackerPageError',
} as const;
